import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import blogimg15 from '../assets/img/Picture1.jpg'


import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article10 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#3a1772'}} className="post-title pt-3">
                  ARISE Technical Review Meeting in Athens
                
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
            01/11/2024

					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  30px 20px 0px'}} src={blogimg15} title=""  width="400" height="291" />
                <p style={{fontStyle:'italic'}}>Month 9 - Technical review meeting in Athens.</p>
               <p>On October 23, 2024 our ARISE Technical review meeting M9, was convened in Athens bringing together all our project partners. 
                These included <a href="https://hmu.gr/en/home/">Hellenic Mediterranean University</a>, <a href='https://www.santannapisa.it/en'>Sant’Anna School of Advanced Studies</a>, 
<a href='https://www.tu-darmstadt.de/'> Technical University of Darmstadt</a>, <a href='https://www.cea.fr/english'> CEA</a>, 
<a href='https://www.alsymex-alcen.com/'> ALSYMEX</a>, <a href='https://plaixus.com/'> PLAIXUS</a>, <a href='https://bendabl.com/'> BENDABL</a>, <a href='https://www.certh.gr/root.en.aspx'>Centre for Research and Technology Hellas</a>, 
<a href='https://www.csic.es/en'> Spanish National Research Council</a>, <a href='https://ubitech.eu/'> UBITECH</a>, 
<a href='https://www.probotica.hr/'> PROBOTICA</a>, <a href='https://telekinesis.ai/'> TELEKINESIS</a>, <a href='https://www.elvan.gr/en/'>ELVAN</a>, 
<a href='https://www.jepco.co.uk/'> JEPCO</a> and the <a href='https://www.twi-global.com/innovation-network/centres/innovation-centres/essex-innovation-centre'>Essex Innovation Centre </a> 
(essex.ai: a <a href='https://www.essex.ac.uk/'> University of Essex</a>–TWI partnership, represented by TWI Hellas on the project)
 
               </p>
 

  <p>As the project reached its Month 9, we conducted the Technical review meeting, hosted by TWI Hellas. 
    Twi Hellas was in charge of organising all management arrangements and also led the project dissemination, impact analysis and policy recommendation development for the ARISE toolchain. 
    During the meeting, partners showcased their cutting-edge research, technological advances, and aligned strategies for the project’s milestones.<span style={{fontStyle:'italic'}}>
    The review was deemed a success, with only a few minor comments from the EU reviewers. 
    These comments will be addressed by the partners over the coming months.  </span></p>
    <p>Thanks to the unique experience gained during the review process and the valuable feedback provided by our project officer 
      <a href='https://www.linkedin.com/in/mariavittoriafrau/'>Maria Vittoria Frau</a> and our reviewers <a href='https://www.linkedin.com/in/ceciliagarc%C3%ADacena/'>Cecilia García Cena</a> and 
      <a href='https://www.linkedin.com/in/erwin-prassler-a63ab82a3/'>Erwin Prassler</a>, the ARISE consortium is now poised to continue driving innovation in both the agriculture and solar sector.</p>

                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article10