import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import blogimg15 from '../assets/img/Picture2.jpg'


import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article11 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#3a1772'}} className="post-title pt-3">
                  ELVAN Technical Visit Takeaways
                
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
            01/12/2024

					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  30px 20px 0px'}} src={blogimg15} title=""  width="420" height="300" />
                <p style={{marginBottom:'0.4em',fontStyle:'italic'}}>What we saw:</p>
               <p style={{marginBottom:'0.4em'}}>- A vast park located in generally inhospitable terrain/environment for humans to be working extensive hours in.
               </p>
               <p style={{marginBottom:'0.4em',fontStyle:'italic'}}>What left an impression:</p>
               <p style={{marginBottom:'0.4em'}}>- The extent of the maintenance work that needs to be done regularly.
               </p>
               <p style={{marginBottom:'0.4em',fontStyle:'italic'}}>What are we using for the project:</p>
               <p style={{marginBottom:'0.4em'}}>- Valuable video feed for 3D reconstruction purposes
               </p>    
               <p style={{marginBottom:'0.4em',fontStyle:'italic'}}>Something we didn’t know:</p>
               <p style={{marginBottom:'0.4em'}}>- The variety of panel configurations that the platform will need to cater to..
               </p>
               <p >-	Additional industry regulations must be studied closely and accounted for in system design.</p>
 

  <p>Representatives of the ARISE consortium visited the ELVAN photovoltaic park facility on October 24th 2024, which is located on the rural outskirts of the city, about a 45 minute ride from downtown Athens.</p>
<p>First, <b>what we saw</b> included the vast scale and layout of the park, with rows of solar panels extending over uneven terrain. We observed the operational challenges, such as navigating said terrain, accessing panel surfaces at varying heights, and managing the high temperatures and strong gusts of wind which are characteristic of the Greek countryside. This real-world exposure underscored the necessity for a robust and agile robotic system capable of maneuvering effectively while addressing diverse maintenance tasks.</p>
<p>
<b>What left a strong impression</b> was that the complexity of the maintenance requirements will likely stress the capabilities of the system. We were hard pressed to find panels not affected by even minor cracking, which really highlighted the importance of these tasks. Moreover, the detailed discussions with park operators emphasized the importance of reliability and user-friendliness in any potential technological solution.
</p>
<p>In terms of <b>what we could utilize in the project</b>, the visit clarified key design requirements. For instance, the terrain calls for adaptable locomotion systems, and the precise nature of tasks like sealant application confirms the foreseen need for advanced end-effectors and tool integration. Furthermore, the video footage captured during the visit will be instrumental for 3D reconstruction, aiding in the development of navigation algorithms and task-specific simulations.</p>
<p>Finally, <b>something we didn’t know</b> prior to the visit was the extent of variability in panel configurations and mounting systems across the park. This diversity presents both a challenge and an opportunity, as it requires our robotic platform to be highly versatile while also validating the economic potential for deployment in similarly varied sites worldwide. The park operators also highlighted regulatory considerations that we must account for in the system’s design, which had not been a prominent focus in our earlier planning stages.</p>
<p>All in all, the visit to the ELVAN  photovoltaic park provided invaluable insights that will shape the development of our endeavour.</p>
                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article11